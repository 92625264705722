exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chrome-js": () => import("./../../../src/pages/chrome.js" /* webpackChunkName: "component---src-pages-chrome-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quizboot-js": () => import("./../../../src/pages/quizboot.js" /* webpackChunkName: "component---src-pages-quizboot-js" */),
  "component---src-pages-tp-integrations-js": () => import("./../../../src/pages/tp-integrations.js" /* webpackChunkName: "component---src-pages-tp-integrations-js" */),
  "component---src-pages-trivago-js": () => import("./../../../src/pages/trivago.js" /* webpackChunkName: "component---src-pages-trivago-js" */),
  "component---src-pages-trustpilot-shopify-js": () => import("./../../../src/pages/trustpilot-shopify.js" /* webpackChunkName: "component---src-pages-trustpilot-shopify-js" */),
  "component---src-pages-trustscore-js": () => import("./../../../src/pages/trustscore.js" /* webpackChunkName: "component---src-pages-trustscore-js" */)
}

